import {
  GetTimeSlotsRequest,
  Status,
  TimeSlot,
} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import {getTimeSlots as getTimeSlotsService} from '@wix/ambassador-table-reservations-v1-time-slot/http'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {utcToZonedTime, zonedTimeToUtc} from '@wix/table-reservations-lib/timezone'
import addDays from 'date-fns/addDays'
import isSameDay from 'date-fns/isSameDay'
import isPast from 'date-fns/isPast'

import {ITimeSlotsByDays} from '../components/Reservations/constants'

const getTimeSlots = async (
  flowAPI: ControllerFlowAPI,
  params: GetTimeSlotsRequest,
  tz?: string | null,
): Promise<ITimeSlotsByDays> => {
  if (params.date && tz) {
    params.date = zonedTimeToUtc(params.date, tz)
  }

  const timeSlots = await getTimeSlotsRequest(flowAPI, params)

  if (isSomeSlotAvailable(timeSlots)) {
    return {
      [params.date.toDateString()]: timeSlots.map((t) => ({
        ...t,
        startDate: utcToZonedTime(t.startDate!, tz),
      })),
    }
  } else {
    const days = getDaysAroundDate(params.date)

    const responses = (
      await Promise.all(
        days.map((date) =>
          getTimeSlotsRequest(flowAPI, {
            ...params,
            date,
            slotsBefore: 2,
            slotsAfter: 2,
          }),
        ),
      )
    ).map((slots) =>
      slots.map((t) => ({
        ...t,
        startDate: utcToZonedTime(t.startDate!, tz),
      })),
    )

    const result = {}

    days.forEach((day, index) => {
      if (isSomeSlotAvailable(responses[index])) {
        result[day.toDateString()] = responses[index]
      }
    })

    return result
  }
}

const getDaysAroundDate = (date: Date) => {
  const DAYS_COUNT = 5
  const MAX_DAYS_BEFORE = 2
  const days: Date[] = []

  let i = 0

  while (days.length < DAYS_COUNT) {
    const day = addDays(addDays(date, -MAX_DAYS_BEFORE), i)

    // TODO: respect schedule
    if (!isSameDay(date, day) && !isPast(day)) {
      days.push(day)
    }

    i++
  }

  return days
}

const isSomeSlotAvailable = (timeSlots: TimeSlot[]) =>
  timeSlots.some((slot) => slot.status === Status.AVAILABLE)

const getTimeSlotsRequest = async (
  flowAPI: ControllerFlowAPI,
  params: GetTimeSlotsRequest,
): Promise<TimeSlot[]> => {
  const req = getTimeSlotsService({slotsBefore: 7, slotsAfter: 7, ...params})

  const res = await flowAPI.httpClient.request(req)

  return res.data.timeSlots ?? []
}

const EXTENDED_TYPE_SLOTS_COUNT = 5

export const timeSlotsService = {
  getTimeSlots,
  EXTENDED_TYPE_SLOTS_COUNT,
}
