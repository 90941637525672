import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {DropdownOptionProps} from 'wix-ui-tpa/cssVars'

interface GetRange {
  start: number
  end: number
  step?: number
  size?: number
}

export const getRange = ({
  start,
  end,
  step = 1,
  size = Math.ceil((end - start) / step) + 1,
}: GetRange) => Array.from({length: size}, (_, i) => i * step + start)

export const getPartySizeOptionsForReservationLocation = (
  t: Function,
  reservationLocation: ReservationLocation | undefined,
): DropdownOptionProps[] => {
  const minPartiesSize =
    reservationLocation?.configuration?.onlineReservations?.partiesSize?.min ?? 1
  const maxPartiesSize =
    reservationLocation?.configuration?.onlineReservations?.partiesSize?.max ?? 1
  const isLargePartiesCallbackEnabled =
    reservationLocation?.configuration?.onlineReservations?.showPhoneNumber ?? false

  const isManualApprovalEnabled =
    !!reservationLocation?.configuration?.onlineReservations?.manualApproval?.enabled

  const manualApprovalPartySizeThreshold =
    reservationLocation?.configuration?.onlineReservations?.manualApproval?.partySizeThreshold

  const isLocationCompletelyManual =
    isManualApprovalEnabled && manualApprovalPartySizeThreshold === minPartiesSize
  const isLocationCompletelyAuto = !isManualApprovalEnabled

  const partySizeRange = getRange({
    start: minPartiesSize,
    end: maxPartiesSize,
  })

  const options = partySizeRange.map((size) => {
    const value = t('uou-reservations.shared.guests', {number: size})

    const subtitle =
      isLocationCompletelyManual || isLocationCompletelyAuto
        ? undefined
        : size >= manualApprovalPartySizeThreshold!
        ? t('uou-reservations.shared.guests.requests-only')
        : t('uou-reservations.shared.guests.auto-approve')

    return {
      id: size.toString(),
      value,
      isSelectable: true,
      subtitle,
    }
  }) as DropdownOptionProps[]

  if (isLargePartiesCallbackEnabled) {
    const largeParty = maxPartiesSize + 1

    const value = t('uou-reservations.shared.large-parties-guests', {number: largeParty})

    options.push({
      id: largeParty.toString(),
      value,
      isSelectable: true,
    })
  }

  return options
}
