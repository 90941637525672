import {StyleParamType, wixColorParam, wixFontParam, createStylesParams} from '@wix/tpa-settings'

export type TimeSlotsDefaultStylesParams = {
  timeSlotsDefaultFont: StyleParamType.Font
  timeSlotsDefaultColor: StyleParamType.Color
  timeSlotsDefaultFillOpacityColor: StyleParamType.Color
  timeSlotsDefaultBorderColor: StyleParamType.Color
  timeSlotsDefaultBorderWidth: StyleParamType.Number
  timeSlotsDefaultCornerRadius: StyleParamType.Number
}

export const timeSlotsDefaultStylesParams = createStylesParams<TimeSlotsDefaultStylesParams>({
  timeSlotsDefaultFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: (params) =>
      wixFontParam('Body-M', {
        size: params.isMobile ? 14 : 16,
      })(params),
  },
  timeSlotsDefaultColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  timeSlotsDefaultFillOpacityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  timeSlotsDefaultBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  timeSlotsDefaultBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  timeSlotsDefaultCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
})

export type TimeSlotsSelectedStylesParams = {
  timeSlotsSelectedTextColor: StyleParamType.Color
  timeSlotsSelectedFillColor: StyleParamType.Color
}

export const timeSlotsSelectedStylesParams = createStylesParams<TimeSlotsSelectedStylesParams>({
  timeSlotsSelectedTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  timeSlotsSelectedFillColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
})
