import {dataHooks} from '@wix/data-hooks'

export const reservationsDataHooks = dataHooks<{
  root: {}
}>('reservations')

export enum ReservationsPageType {
  DEFAULT,
  EXTENDED,
  EMPTY,
}
