import React, {useState} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import isAfter from 'date-fns/isAfter'
import {Text} from 'wix-ui-tpa/cssVars'
import {useStyles} from '@wix/tpa-settings/react'

import {stylesParams} from '../../stylesParams'
import {ITimeSlotsByDays} from '../../constants'
import {TagsList} from '../TagsList'
import {getMediumDate, getShortTime} from '../../../../utils/dateString'
import {SelectTimeSlotError} from '../SelectTimeSlotError'
import {LayoutSize} from '../../../../components-shared/LayoutSizeProvider/types'

import {st, classes} from './TimeSlotsListExtended.st.css'
import {timeSlotsListExtendedDataHooks} from './constants'

interface Props {
  regionalSettings?: string
  items: ITimeSlotsByDays
  partySize: number
  selectedDate: Date
  selectedTimeSlot?: Date
  showSelectTimeSlotError: boolean
  onSelectTimeSlot: (item) => void
  layoutSize: LayoutSize
}

export const TimeSlotsListExtended: React.FC<Props> = ({
  layoutSize,
  regionalSettings,
  items = {},
  partySize,
  selectedDate,
  selectedTimeSlot,
  showSelectTimeSlotError,
  onSelectTimeSlot,
}) => {
  const styles = useStyles()
  const {t} = useTranslation()

  const dates = Object.keys(items)
  const [isFullyOpened, setIsFullyOpened] = useState<boolean>(dates?.length <= 3)
  const datesToShow = isFullyOpened ? dates : dates.slice(0, 3)

  const secondaryTitleTag =
    styles.get(stylesParams.generalSecondaryTitleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.generalSecondaryTitleFont).htmlTag

  if (!Object.values(items)?.length) {
    return null
  }

  return (
    <div className={st(classes.root)} data-hook={timeSlotsListExtendedDataHooks.root()}>
      <Text tagName="p" className={classes.notification} role="alert">
        {t('uou-reservations.reservations.time-slot-unavailable', {
          number: partySize,
          date: getMediumDate(selectedDate, regionalSettings),
          time: getShortTime(selectedDate, regionalSettings),
        })}
      </Text>

      {showSelectTimeSlotError && <SelectTimeSlotError />}

      <Text tagName={secondaryTitleTag} className={classes.secondaryTitle}>
        {t('uou-reservations.reservations.time-slot-other-dates.text')}
      </Text>

      {datesToShow
        .sort((a, b) => {
          const aDate = new Date(a)
          const bDate = new Date(b)

          return isAfter(aDate, bDate) ? 1 : -1
        })
        .map((key, index) => (
          <section className={classes.section} key={index}>
            <TagsList
              layoutSize={layoutSize}
              label={getMediumDate(new Date(key), regionalSettings)}
              timeSlots={items?.[key] ?? []}
              onSelectTimeSlot={onSelectTimeSlot}
              regionalSettings={regionalSettings}
              selectedTimeSlot={selectedTimeSlot}
              extended
            />
          </section>
        ))}

      {!isFullyOpened ? (
        <a
          href="#"
          className={classes.overdoneLink}
          onClick={(e) => {
            e.preventDefault()

            setIsFullyOpened(true)
          }}
        >
          {t('uou-reservations.reservations.time-slot-other-dates.cta')}
        </a>
      ) : null}
    </div>
  )
}
