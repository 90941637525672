import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export type TextsSettingsParams = {
  titleText: SettingsParamType.String
  titleRequestText: SettingsParamType.String
  descriptionText: SettingsParamType.String
  descriptionRequestsText: SettingsParamType.String
  buttonLabelText: SettingsParamType.String
  buttonLabelRequestText: SettingsParamType.String
}

export const textsSettingsParams = createSettingsParams<TextsSettingsParams>({
  titleText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.title'),
  },
  titleRequestText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.title.request'),
  },
  descriptionText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.subtitle'),
  },
  descriptionRequestsText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.subtitle.request'),
  },
  buttonLabelText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.continue-cta'),
  },
  buttonLabelRequestText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.continue-cta.request'),
  },
})
