import {useState, useEffect} from 'react'
import {BusinessSchedule} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {setHMToDate} from '@wix/table-reservations-lib/schedule'
import {utcToZonedTime} from '@wix/table-reservations-lib/timezone'

import {getNearestQuarterTime, getNearestWorkingDate} from './date'
import {getTimeOptions, findTimeOption, TimeOption} from './timeOptions'

interface UseSelectedDateAndTimeParams {
  businessSchedule: BusinessSchedule
  preselectedDate?: string
  regionalSettings?: string
  isPreselectedDateValid?: boolean
  timeZone?: string | null
}

export const useSelectedDateAndTime = ({
  businessSchedule,
  preselectedDate,
  regionalSettings,
  isPreselectedDateValid,
  timeZone,
}: UseSelectedDateAndTimeParams) => {
  const [selectedDate, setSelectedDate] = useState<Date>(() =>
    getSelectedDate({
      businessSchedule,
      preselectedDate,
      regionalSettings,
      isPreselectedDateValid,
      timeZone,
    }),
  )
  const [timeOptions, setTimeOptions] = useState<TimeOption[]>(
    getTimeOptions({
      date: selectedDate,
      businessSchedule,
      regionalSettings,
      timeZone,
    }),
  )
  const [selectedTime, setSelectedTime] = useState<TimeOption | undefined>(() =>
    selectedDate ? findTimeOption(timeOptions, selectedDate) : timeOptions[0],
  )

  const onSelectedDateChange = (newDate: Date) => {
    const newTimeOptions = getTimeOptions({
      date: newDate,
      businessSchedule,
      regionalSettings,
      timeZone,
    })

    setTimeOptions(newTimeOptions)
    setSelectedTime(newTimeOptions[0])

    if (newTimeOptions[0]) {
      const {hours, minutes} = newTimeOptions[0].data
      setSelectedDate(setHMToDate(newDate, hours, minutes))
    }
  }

  const onSelectedTimeChange = (timeOption: TimeOption) => {
    const {hours, minutes} = timeOption?.data

    setSelectedTime(timeOption)
    setSelectedDate(setHMToDate(selectedDate, hours, minutes))
  }

  useEffect(() => {
    const newSelectedDate = getSelectedDate({
      businessSchedule,
      preselectedDate,
      regionalSettings,
      isPreselectedDateValid,
      timeZone,
    })

    const newTimeSlots = getTimeOptions({
      date: newSelectedDate,
      businessSchedule,
      regionalSettings,
      timeZone,
    })

    const newSelectedTime = newSelectedDate
      ? findTimeOption(newTimeSlots, newSelectedDate)
      : newTimeSlots[0]

    setSelectedDate(newSelectedDate)
    setTimeOptions(newTimeSlots)
    setSelectedTime(newSelectedTime)
  }, [businessSchedule])

  return {
    selectedDate,
    selectedTime,
    timeOptions,
    onSelectedDateChange,
    onSelectedTimeChange,
  }
}

const getSelectedDate = ({
  preselectedDate,
  isPreselectedDateValid,
  timeZone,
  businessSchedule,
  regionalSettings,
}: UseSelectedDateAndTimeParams) =>
  preselectedDate && isPreselectedDateValid
    ? getNearestQuarterTime(utcToZonedTime(preselectedDate, timeZone))
    : getNearestWorkingDate({businessSchedule, regionalSettings, timeZone})
