import React from 'react'
import {Button, Dialog} from 'wix-ui-tpa/cssVars'
import {useTranslation} from '@wix/yoshi-flow-editor'

import {LayoutSize} from '../LayoutSizeProvider/types'

import {NotAcceptingBanner} from './NotAcceptingBanner'
import {classes} from './styles.st.css'

type NotAcceptingModalProps = {
  isOpen: boolean
  onClose: () => void
  layoutSize: LayoutSize
  bannerClassName?: string
}

export const NotAcceptingModal: React.FC<NotAcceptingModalProps> = ({
  isOpen,
  onClose,
  layoutSize,
  bannerClassName,
}) => {
  const {t} = useTranslation()

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className={classes.notAcceptingModal}>
        <NotAcceptingBanner layoutSize={layoutSize} className={bannerClassName} />
        <Button onClick={onClose}>
          {t('uou-reservations.reservations.not-accepting-modal-close-button')}
        </Button>
      </div>
    </Dialog>
  )
}
