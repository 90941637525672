import {StyleParamType, wixColorParam, wixFontParam, createStylesParams} from '@wix/tpa-settings'

export type GeneralStylesParams = {
  generalBackgroundColor: StyleParamType.Color
  generalBorderColor: StyleParamType.Color
  generalBorderWidth: StyleParamType.Number
  generalBorderRadius: StyleParamType.Number
  generalTitleFont: StyleParamType.Font
  generalTitleColor: StyleParamType.Color
  generalSubtitleFont: StyleParamType.Font
  generalSubtitleColor: StyleParamType.Color
  generalSecondaryTitleFont: StyleParamType.Font
  generalSecondaryTitleColor: StyleParamType.Color
  generalBodyFont: StyleParamType.Font
  generalBodyColor: StyleParamType.Color
  generalDividerColor: StyleParamType.Color
  generalDividerWidth: StyleParamType.Number
}

export const generalStylesParams = createStylesParams<GeneralStylesParams>({
  generalBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  generalBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0),
  },
  generalBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  generalBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  generalTitleFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: (params) =>
      wixFontParam('Page-title', {
        htmlTag: 'h1',
        size: params.isMobile ? 28 : 32,
      })(params),
  },
  generalTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  generalSubtitleFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Body-M', {size: 16, htmlTag: 'p'}),
  },
  generalSubtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  generalSecondaryTitleFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Body-M', {size: 16, htmlTag: 'h2'}),
  },
  generalSecondaryTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  generalBodyFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Body-M', {size: 16}),
  },
  generalBodyColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  generalDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  generalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
})
