import {
  CustomFieldDefinition,
  ReservationLocation,
} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {TFunction} from '@wix/yoshi-flow-editor'
import {DropdownOptionProps} from 'wix-ui-tpa/cssVars'

export const getEnabledReservationLocations = (reservationLocations: ReservationLocation[]) =>
  reservationLocations.filter(
    ({configuration}) => configuration?.onlineReservations?.onlineReservationsEnabled === true,
  )

export const getReservationLocationById = (
  reservationLocationId: string,
  reservationLocations: ReservationLocation[],
): ReservationLocation | undefined =>
  reservationLocations.find(({id}) => id === reservationLocationId)

export const getIsLocationCompletelyManual = (location: ReservationLocation) => {
  const minPartiesSize = location.configuration?.onlineReservations?.partiesSize?.min ?? 1

  return (
    location.configuration?.onlineReservations?.manualApproval?.enabled &&
    location.configuration.onlineReservations.manualApproval.partySizeThreshold === minPartiesSize
  )
}

export const getIsLocationCompletelyAuto = (location: ReservationLocation) =>
  !location.configuration?.onlineReservations?.manualApproval?.enabled

export const getReservationLocationsOptions = (
  reservationLocations: ReservationLocation[],
  t: TFunction,
): DropdownOptionProps[] => {
  const isAllLocationsCompletelyManual = reservationLocations.every(getIsLocationCompletelyManual)
  const isAllLocationsCompletelyAuto = reservationLocations.every(getIsLocationCompletelyAuto)

  return reservationLocations.map((location) => {
    const isLocationCompletelyManual = getIsLocationCompletelyManual(location)
    const isLocationCompletelyAuto = getIsLocationCompletelyAuto(location)

    let subtitle
    if (isAllLocationsCompletelyManual || isAllLocationsCompletelyAuto) {
      subtitle = undefined
    } else if (isLocationCompletelyManual) {
      subtitle = t('uou-reservations.shared.guests.requests-only')
    } else if (isLocationCompletelyAuto) {
      subtitle = t('uou-reservations.shared.guests.auto-approve')
    }

    return {
      id: location.id!,
      value: location.location?.name!,
      subtitle,
      isSelectable: true,
    }
  })
}

export const getInitialReservationLocation = (
  reservationLocations: ReservationLocation[],
  selectedReservationLocationId?: string,
): ReservationLocation | undefined => {
  if (selectedReservationLocationId) {
    const selectedReservationLocation = getReservationLocationById(
      selectedReservationLocationId,
      reservationLocations,
    )

    return selectedReservationLocation ?? reservationLocations[0]
  }

  const defaultLocation = reservationLocations.find(
    (location) =>
      location.default &&
      location.configuration?.onlineReservations?.onlineReservationsEnabled === true,
  )

  if (defaultLocation) {
    return defaultLocation
  }

  const firstAvailableReservationLocation = reservationLocations.find(
    (location) => location.configuration?.onlineReservations?.onlineReservationsEnabled === true,
  )

  return firstAvailableReservationLocation ?? reservationLocations[0]
}

export const getFormUICustomFieldsFromArea = (
  customFieldDefinitions: CustomFieldDefinition[] = [],
) => {
  const customFields = {}

  for (const {id, required} of customFieldDefinitions) {
    customFields[id ?? ''] = {
      value: '',
      isRequired: required ?? false,
      valid: true,
    }
  }

  return customFields
}
