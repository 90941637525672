import {
  formSettingsParams,
  FormSettingsParams,
} from '../../components-shared/Settings/FormDesign/settingsParams'
import {SettingsParamsType} from '../../types/createStylesParams'

import {textsSettingsParams, TextsSettingsParams} from './Settings/Tabs/Texts/settingsParams'
import {layoutSettingsParams, LayoutSettingsParams} from './Settings/Tabs/Layout/settingsParams'
import {
  designGeneralSettingsParams,
  DesignGeneralSettingsParams,
} from './Settings/Tabs/Design/General/settingsParams'

export type SettingsParams = DesignGeneralSettingsParams &
  LayoutSettingsParams &
  TextsSettingsParams &
  FormSettingsParams

export const settingsParams: SettingsParamsType<SettingsParams> = {
  ...designGeneralSettingsParams,
  ...layoutSettingsParams,
  ...textsSettingsParams,
  ...formSettingsParams,
}

export default settingsParams
